import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Container } from "react-bootstrap"

const Page = ({ data }) => {
    const { mdx } = data
    const { body, frontmatter } = mdx

    return (
      <Layout>
        <SEO title={frontmatter.title} />
        <Container className="my-4">
          <MDXRenderer>
              {body}
          </MDXRenderer>
        </Container>
      </Layout>
    )
  }  

export default Page

export const pageQuery = graphql`
    query($id: String) {
        mdx(id: { eq: $id }) {
            body
            frontmatter {
                title
            }
        }
    }`
